<template>
	<StatementsLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<StatementsTab
						:statements="statements"
						@load-statements="loadStatements"
					/>
				</template>
				<template #1>
					<StatementTranslationsTab
						:statements="statements"
						:languages="languages"
						:previously-selected-language="previouslySelectedLanguage"
						@load-statements="loadStatements"
					/>
				</template>
			</TabItems>
		</template>
	</StatementsLayout>
</template>

<script>
import StatementsLayout from './statements-layout.vue'
import StatementsTab from './statements-tab.vue'
import StatementTranslationsTab from './statement-translations-tab.vue'
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { getStatements } from '../../../../../shared/utils/api/statements.js'
import { changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../shared/state/meta-data.js'

export default {
	components: { StatementsLayout, Tabs, TabItems, StatementsTab, StatementTranslationsTab },
	props: {
		accessedVia: String,
		previouslySelectedLanguage: Number
	},
	setup () {
		return {
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		const ACCESSED_VIA_STATEMENT_TRANSLATIONS = 'statement-translations'
		return {
			ACCESSED_VIA_STATEMENT_TRANSLATIONS,
			tabs: [{ title: useLabels('Statements'), slot: '0' }, { title: useLabels('Statement Translations'), slot: '1' }],
			tab: '1',
			statements: [],
			languages: []
		}
	},
	async created () {
		if (this.accessedVia === this.ACCESSED_VIA_STATEMENT_TRANSLATIONS) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
		this.loadStatements()
	},
	methods: {
		async loadStatements () {
			const { data: statements } = await getStatements()
			this.statements = statements
		}
	}
}
</script>
