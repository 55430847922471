<template>
	<div class="cassie-vertical-md pt-2">
		<FilterBar
			:search-query.sync="statementTextSearchQuery"
			:search-query-label="'Statement' | useLabels"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeStatementTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedChannelId"
					:label="'Channel' | useLabels"
					:items="channelsFilterItems"
					custom-sort
					@input="changeChannelId($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Statements' | useLabels }}
			</template>
			<template #title-action>
				<SecondaryActionButton @click="viewConsentStructure">
					View Consent Structure
				</SecondaryActionButton>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredStatements"
					sort-by="statementId"
					sort-desc
					@click:row="onEditStatement"
				>
					<template #item.statementText="{ item }">
						{{ getTruncatedText(item.statementText, 50) }}
					</template>
					<template #item.channelName="{ item }">
						{{ getTruncatedText(item.channelName, 50) }}
					</template>
					<template #item.status="{ item }">
						{{ getStatus(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="canInteractWithDraftStatements"
							tooltip-text="Edit Statement"
							@click="onEditStatement(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Statement"
							@click="onEditStatement(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="canDeleteStatements"
							tooltip-text="Delete Statement"
							@click.stop.prevent="onRemoveStatement(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="statementToBeRemoved"
			:entity-name="statementToBeRemoved.statementText"
			entity-type="Statement"
			@close="statementToBeRemoved = null"
			@delete="deleteStatement"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../shared/utils/date-formatting.js'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { showStatementDrafts } from '../../../../../shared/state/config-keys.js'
import { statementTextSearchQuery, changeStatementTextSearchQuery } from '../../../../../shared/state/statements.js'
import { changeChannelId, selectedChannelId } from '../../../../../shared/state/channels.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { changeMetaDataValueSearchQuery, changeMetaDataKey, metaDataValueSearchQuery, selectedMetaDataKey } from '../../../../../shared/state/meta-data.js'
import { deleteStatement } from '../../../../../shared/utils/api/statements.js'
import { MANAGE_STATEMENT, CONSENT_STRUCTURE } from '../../../router/route-names.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { CAN_DELETE_STATEMENTS, CAN_CREATE_DRAFT_STATEMENTS, CAN_APPROVE_DRAFT_STATEMENTS, CAN_PUBLISH_DRAFT_STATEMENTS, COMPONENTS_MODULE_FULL_PERMISSIONS, STATEMENTS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { STATEMENT_STATUS } from '../statements/statement-enums.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'

export default {
	components: {
		FilterBar,
		Dropdown,
		SectionCard,
		DataTable,
		IconButton,
		ConfirmDeleteModal,
		SecondaryActionButton
	},
	props: {
		statements: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			statementTextSearchQuery,
			changeStatementTextSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeChannelId,
			selectedChannelId,
			changeMetaDataValueSearchQuery,
			changeMetaDataKey,
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			showStatementDrafts,
			getTruncatedText
		}
	},
	data () {
		return {
			channels: [],
			statementToBeRemoved: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		filteredStatements () {
			return this.statements.filter(({ statementText, brandID, channelID, metaData }) => {
				let check = true
				if (statementTextSearchQuery.value) check = statementText.toLowerCase().includes(statementTextSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandID
				if (selectedChannelId.value !== null) check = check && selectedChannelId.value === channelID
				if (selectedMetaDataKey.value !== null) check = check && metaData.some(meta => meta.metaDataKeyId === selectedMetaDataKey.value)
				if (metaDataValueSearchQuery.value) check = check && metaData.some(meta => meta.metaDataValue.toLowerCase().includes(metaDataValueSearchQuery.value.toLowerCase()))
				return check
			})
		},
		channelsFilterItems () {
			const channels = [...this.channels]
			return [
				{
					text: 'View All',
					value: null
				},
				...channels.sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		tableHeaders () {
			let tableHeaders = [
				{
					text: 'ID',
					value: 'statementId',
					width: '10%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '15%'
				},
				{
					text: useLabels('Statement Text'),
					value: 'statementText',
					width: '20%'
				},
				{
					text: useLabels('Channel Name'),
					value: 'channelName',
					width: '20%'
				},
				{
					text: 'Status',
					value: 'status',
					width: '15%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '15%',
					sortable: false
				}
			]
			if (showStatementDrafts.value === false) tableHeaders = tableHeaders.filter(({ value }) => value !== 'status')

			return tableHeaders
		},
		canInteractWithDraftStatements () {
			if (showStatementDrafts.value === false) {
				return this.productAreaPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) || this.productAreaPermission(STATEMENTS_FULL_PERMISSIONS)
			}
			return (this.productAreaPermission(CAN_PUBLISH_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_APPROVE_DRAFT_STATEMENTS))
		},
		canDeleteStatements () {
			return this.productAreaPermission(CAN_DELETE_STATEMENTS) || this.productAreaPermission(STATEMENTS_FULL_PERMISSIONS)
		}
	},
	created () {
		this.getChannels()
	},
	methods: {
		async getChannels () {
			const { data: { channels } } = await getPreferenceChannels()
			this.channels = channels.map(({ channelId, channelName }) => (({
				text: channelName,
				value: channelId
			})))
		},
		onEditStatement (statementToEdit) {
			this.$router.push({
				name: MANAGE_STATEMENT,
				params: {
					statementToEdit,
					channelName: statementToEdit.channelName,
					accessedVia: 'statements'
				}
			})
		},
		onRemoveStatement (statement) {
			this.statementToBeRemoved = statement
		},
		async deleteStatement () {
			await deleteStatement(this.statementToBeRemoved.statementId)
			showSnackbar('This statement has been deleted')
			this.$emit('load-statements')
			this.statementToBeRemoved = null
		},
		getDraftStatus (statusCode, queuedActionDateTime) {
			switch (statusCode) {
				case STATEMENT_STATUS.NEW:
					return 'Draft: New'
				case STATEMENT_STATUS.SUBMITTED:
					return 'Draft: Submitted'
				case STATEMENT_STATUS.APPROVED:
					return 'Draft: Approved'
				case STATEMENT_STATUS.AWAITING_PUBLISH:
					return 'Draft: Live on ' + format(new Date(queuedActionDateTime), DATE_TIME_FORMAT_NO_SECONDS)
				default:
					return ''
			}
		},
		getStatus (statement) {
			const liveTranslatedStatementText = statement.liveStatements?.find(({ languageId }) => languageId === 0)?.statementText
			const selectedLanguageDraftStatement = statement.draftStatements?.find(({ languageId }) => languageId === 0)
			const liveStatusText = liveTranslatedStatementText ? 'Published' : ''
			const draftStatusText = selectedLanguageDraftStatement ? this.getDraftStatus(selectedLanguageDraftStatement.draftState, selectedLanguageDraftStatement.queuedActionDateTime) : ''
			const separatorText = liveStatusText.length > 0 && draftStatusText.length > 0 ? ', ' : ''

			return liveStatusText + separatorText + draftStatusText
		},
		clearFilters () {
			this.selectedChannelId = null
		},
		viewConsentStructure () {
			this.$router.push({
				name: CONSENT_STRUCTURE,
				params: {
					accessedVia: 'statements'
				}
			})
		}
	}
}
</script>
