<template>
	<div class="cassie-vertical-md pt-2">
		<FilterBar
			:search-query.sync="statementTextSearchQuery"
			:search-query-label="'Statement' | useLabels"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeStatementTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedChannelId"
					:label="'Channel' | useLabels"
					:items="channelsFilterItems"
					custom-sort
					@input="changeChannelId($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Statement Translations' | useLabels }}
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					:items="assignedLanguagesItems"
					@input="selectedLanguageId = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredStatementTranslations"
					sort-by="statementId"
					sort-desc
					@click:row="onEditStatementTranslation"
				>
					<template #item.statementText="{ item }">
						{{ item.statementText ? getTruncatedText(item.statementText, 50) : null }}
					</template>
					<template #item.channelName="{ item }">
						{{ item.channelName ? getTruncatedText(item.channelName, 50) : null }}
					</template>
					<template #item.translationStatus="{ item }">
						{{ getTranslationStatus(item) }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="canInteractWithDraftStatements"
							tooltip-text="Edit Statement translation"
							@click="onEditStatementTranslation(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else-if="selectedLanguageIdOrDefault != null"
							tooltip-text="View Statement Translation"
							@click="onEditStatementTranslation(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="canDeleteStatements"
							tooltip-text="Delete Statement translation"
							@click.stop.prevent="onRemoveStatementTranslation(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="statementTranslationToBeRemoved"
			:entity-name="statementTranslationToBeRemoved.statementText"
			entity-type="Statement Translation"
			@close="statementTranslationToBeRemoved = null"
			@delete="deleteStatementTranslation"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../shared/utils/date-formatting.js'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { showStatementDrafts } from '../../../../../shared/state/config-keys.js'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import { deleteStatementTranslation } from '../../../../../shared/utils/api/statements.js'
import { statementTextSearchQuery, changeStatementTextSearchQuery } from '../../../../../shared/state/statements.js'
import { changeChannelId, selectedChannelId } from '../../../../../shared/state/channels.js'
import { changeAdminPortalBrandFilter, selectedAdminPortalBrandId } from '../../../../../shared/state/brands.js'
import { changeMetaDataValueSearchQuery, changeMetaDataKey, metaDataValueSearchQuery, selectedMetaDataKey } from '../../../../../shared/state/meta-data.js'
import { MANAGE_STATEMENT_TRANSLATION } from '../../../router/route-names.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { CAN_DELETE_STATEMENTS, CAN_CREATE_DRAFT_STATEMENTS, CAN_APPROVE_DRAFT_STATEMENTS, CAN_PUBLISH_DRAFT_STATEMENTS, COMPONENTS_MODULE_FULL_PERMISSIONS, STATEMENTS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		FilterBar,
		Dropdown,
		SectionCard,
		DataTable,
		ConfirmDeleteModal,
		IconButton
	},
	props: {
		metaData: {
			type: Array,
			default: () => []
		},
		statements: {
			type: Array,
			default: () => []
		},
		previouslySelectedLanguage: Number
	},
	setup () {
		return {
			statementTextSearchQuery,
			changeStatementTextSearchQuery,
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			changeChannelId,
			selectedChannelId,
			changeMetaDataValueSearchQuery,
			changeMetaDataKey,
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			showStatementDrafts
		}
	},
	data () {
		return {
			selectedLanguageId: null,
			channels: [],
			assignedLanguages: [],
			statementTranslationToBeRemoved: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		statementTranslations () {
			return this.statements
				.map(statement => {
					const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
					const liveTranslatedStatementText = statement.liveStatements?.find(({ languageId }) => languageId === language?.value)?.statementText
					const draftTranslatedStatementText = statement.draftStatements?.find(({ languageId }) => languageId === language?.value)?.statementText
					const draftTranslatedStatementStateName = statement.draftStatements?.find(({ languageId }) => languageId === language?.value)?.draftStateName
					const draftTranslatedStatementActionCode = statement.draftStatements?.find(({ languageId }) => languageId === language?.value)?.queuedActionCode
					const draftTranslatedStatementPublishOn = statement.draftStatements?.find(({ languageId }) => languageId === language?.value)?.queuedActionDateTime
					const translationSvid = statement.draftStatements?.find(({ languageId }) => languageId === language?.value)?.svid
					const hasTranslation = statement.draftStatements?.find(({ languageId }) => languageId === language?.value) || statement.liveStatements
						?.find(({ languageId }) => languageId === language?.value)
					const selectedLanguageDraftStatement = statement.draftStatements?.find(({ languageId }) => languageId === language?.value)
					return {
						...statement,
						languageName: language?.text,
						languageId: language?.value,
						liveTranslatedStatementText: liveTranslatedStatementText ?? null,
						draftTranslatedStatementText: draftTranslatedStatementText ?? null,
						draftTranslatedStatementStateName: draftTranslatedStatementStateName ?? null,
						publishOn: draftTranslatedStatementActionCode === 700 ? format(new Date(draftTranslatedStatementPublishOn), DATE_TIME_FORMAT_NO_SECONDS) : null,
						translationSvid: translationSvid ?? null,
						selectedLanguageDraftStatement: selectedLanguageDraftStatement ?? null,
						hasTranslation: hasTranslation
					}
				})
		},
		filteredStatementTranslations () {
			return this.statementTranslations.filter(({ statementText, brandID, channelID, metaData }) => {
				let check = true
				if (statementTextSearchQuery.value) check = statementText.toLowerCase().includes(statementTextSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && selectedAdminPortalBrandId.value === brandID
				if (selectedChannelId.value !== null) check = check && selectedChannelId.value === channelID
				if (selectedMetaDataKey.value !== null) check = check && metaData.some(meta => meta.metaDataKeyId === selectedMetaDataKey.value)
				if (metaDataValueSearchQuery.value) check = check && metaData.some(meta => meta.metaDataValue.toLowerCase().includes(metaDataValueSearchQuery.value.toLowerCase()))
				return check
			})
		},
		channelsFilterItems () {
			const channels = [...this.channels]
			return [
				{
					text: 'View All',
					value: null
				},
				...channels.sort((a, b) => a.text.localeCompare(b.text))
			]
		},
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'statementId',
					width: '10%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '15%'
				},
				{
					text: useLabels('Statement Text'),
					value: 'statementText',
					width: '20%'
				},
				{
					text: useLabels('Channel'),
					value: 'channelName',
					width: '20%'
				},
				{
					text: 'Status',
					value: 'translationStatus',
					width: '10%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '15%',
					sortable: false
				}
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId != null) return this.selectedLanguageId
			if (this.previouslySelectedLanguage != null) return this.previouslySelectedLanguage
			return this.assignedLanguagesItems[0]?.value
		},
		canInteractWithDraftStatements () {
			if (showStatementDrafts.value === false) {
				return this.productAreaPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) || this.productAreaPermission(STATEMENTS_FULL_PERMISSIONS)
			}
			return (this.productAreaPermission(CAN_PUBLISH_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_CREATE_DRAFT_STATEMENTS) ||
				this.productAreaPermission(CAN_APPROVE_DRAFT_STATEMENTS))
		},
		canDeleteStatements () {
			return this.productAreaPermission(CAN_DELETE_STATEMENTS) || this.productAreaPermission(COMPONENTS_MODULE_FULL_PERMISSIONS) ||
				this.productAreaPermission(STATEMENTS_FULL_PERMISSIONS)
		}
	},
	created () {
		this.loadLanguages()
		this.getChannels()
	},
	methods: {
		async getChannels () {
			const { data: { channels } } = await getPreferenceChannels()
			this.channels = channels.map(({ channelId, channelName }) => (({
				text: channelName,
				value: channelId
			})))
		},
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		getTranslationStatus (statement) {
			let translationStatus = ''
			if (statement.liveTranslatedStatementText) {
				translationStatus = this.showStatementDrafts ? 'Published, ' : 'Translated'
			}
			if (statement.draftTranslatedStatementText && statement.draftTranslatedStatementStateName) {
				if (statement.publishOn) {
					translationStatus += 'Draft Live on ' + statement.publishOn
				}	else {
					translationStatus += this.showStatementDrafts ? 'Draft ' + statement.draftTranslatedStatementStateName : 'Draft'
				}
			} else {
				translationStatus = translationStatus.replace(', ', '')
			}
			if (translationStatus.length > 0) {
				return translationStatus
			}
			return 'No Translation'
		},
		onEditStatementTranslation (statementTranslationToEdit) {
			this.$router.push({
				name: MANAGE_STATEMENT_TRANSLATION,
				params: {
					statementTranslationToEdit,
					channelName: statementTranslationToEdit.channelName,
					languageName: statementTranslationToEdit.languageName,
					accessedVia: 'statements'
				}
			})
		},
		onRemoveStatementTranslation (statement) {
			this.statementTranslationToBeRemoved = statement
		},
		async deleteStatementTranslation () {
			const translationToBeRemoved = {
				statementId: this.statementTranslationToBeRemoved.statementId,
				languageId: this.statementTranslationToBeRemoved.languageId
			}
			await deleteStatementTranslation(translationToBeRemoved)
			this.statementTranslationToBeRemoved = null
			this.$emit('load-statements')
		},
		getTruncatedText (str, n) {
			return (str.length > n) ? str.slice(0, n - 1) + '...' : str
		},
		clearFilters () {
			this.selectedChannelId = null
		}
	}
}
</script>
